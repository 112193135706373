// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Phaser from 'phaser';
import eventEmitter, { emitGameEvent, GameEvents } from './eventEmiter';
import { calculateScale } from '../../helpers';
import { MultiplierScale } from './MultiplierScale';

export type AviatorAnimationsUnion = 'all' | 'emotions' | 'explosion' | 'fire' | 'flying' | 'flying_with_blinking' | 'flying_with_blinking_hot'
| 'flying_with_emotions' | 'hot' | 'planet' | 'racoon' | 'sun' | 'takeoff' | 'ufo'
export const fileListEdit = [
  {
    name: 'rocket',
    jsonPath: 'aviator/character/characters.json',
    atlasPath: 'aviator/character/characters.atlas',
    skins: ['default'],
    attachments: [null],
    animations: ['all'],
  },
  // {
  //   name: 'rocket',
  //   jsonPath: 'aviator/rocket_small_ship/characters.json',
  //   atlasPath: 'aviator/rocket_small_ship/characters.atlas',
  //   skins: ['default'],
  //   attachments: [null],
  //   animations: ['all'],
  // },
  {
    name: 'background',
    jsonPath: 'aviator/background/background.json',
    atlasPath: 'aviator/background/background.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'racoon',
    jsonPath: 'aviator/racoon/racoon.json',
    atlasPath: 'aviator/racoon/racoon.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: ['racoon', 'racoon_fly1', 'racoon_fly2', 'racoon_fly3'],
  },
  {
    name: 'sun',
    jsonPath: 'aviator/sun/sun.json',
    atlasPath: 'aviator/sun/sun.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'ufo',
    jsonPath: 'aviator/ufo/ufo.json',
    atlasPath: 'aviator/ufo/ufo.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: ['ufo', 'ufo_fly_1', 'ufo_fly_2', 'ufo_fly_3'],
  },
  {
    name: 'planet',
    jsonPath: 'aviator/planet/planet.json',
    atlasPath: 'aviator/planet/planet.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: ['planet', 'planet_fly', 'planet_fly2', 'planet_fly3'],
  },
  {
    name: 'coins_shower',
    jsonPath: 'overlay/coins/coins.json',
    atlasPath: 'overlay/coins/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
] as const;

type FlyingObjectsNameT = 'racoon' | 'sun' | 'ufo' | 'planet'
export class AviatorScene extends Phaser.Scene {
  private rocketData = null;

  private backgroundData = null;

  private racoonData = null;

  private ufoData = null;

  private planetData = null;

  private coinsShowerData = null;

  private sunData = null;

  private isGameStarted = false;

  private multiplierScale = null;

  constructor() {
    super('AviatorScene');
  }

  preload() {
    this.load.setPath('spine');
    // eslint-disable-next-line prefer-destructuring
    this.rocketData = fileListEdit[0];
    // eslint-disable-next-line prefer-destructuring
    this.backgroundData = fileListEdit[1];
    // eslint-disable-next-line prefer-destructuring
    this.sunData = fileListEdit[3];

    const objectsData: {[key in FlyingObjectsNameT]: typeof fileListEdit[number]} = {
      racoon: fileListEdit[2],
      sun: fileListEdit[3],
      ufo: fileListEdit[4],
      planet: fileListEdit[5],
    };
    this.flyingObjectsData = objectsData;

    // eslint-disable-next-line prefer-destructuring
    this.coinsShowerData = fileListEdit[6];
    fileListEdit.forEach((file) => {
      this.load.spine(file.name, file.jsonPath, file.atlasPath);
    });
    this.load.svg('pointer', 'aviator/pointer.svg');
  }

  drawMultiplierScale(value = 0) {
    const multiplierScaleDrawer = this.multiplierScale || new MultiplierScale();
    multiplierScaleDrawer.draw(value);
  }

  create() {
    this.eventEmitter = eventEmitter;
    let rocket;
    let coinsShower;
    let background;
    let sun;
    let currentTrackTime;
    let stopOverlayAnimations = false;
    let delayedAnimations = [];
    this.game.renderer.pipelines.imageSmoothingQuality = 'high';
    this.cameras.main.roundPixels = false;
    this.scale.resolution = window.devicePixelRatio;
    this.cameras.main.setRoundPixels(false);
    this.textures.get('characters.png').setFilter(Phaser.Textures.FilterMode.LINEAR);
    this.textures.get('characters2.png').setFilter(Phaser.Textures.FilterMode.LINEAR);
    const setBackground = () => { // TODO: calculate proper gb by animation seconds
      // eslint-disable-next-line prefer-const
      background = this.add.spine(
        this.game.scale.width / 2,
        this.game.scale.height,
        this.backgroundData.name,
        this.backgroundData.animations[0],
        true,
      );
      // background.setScale(0.8);
      const scaleX = this.cameras.main.width / background.width;
      const scaleY = this.cameras.main.height / background.height;
      const scale = Math.max(scaleX, scaleY);
      background.setScale(scale).setScrollFactor(0);
    };
    setBackground();
    const setAnimation = (char, animation: AviatorAnimationsUnion, loop = false, track = 0, delayInSeconds = 0) => {
      char.state.setAnimation(track, animation, loop, delayInSeconds);
    };

    // eslint-disable-next-line prefer-const
    rocket = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height * 0.83,
      this.rocketData.name,
      null,
      false,
    );
    rocket.setScale(calculateScale(rocket, 93, 190) * 2);
    // rocket.setScale(0.28, 0.25);
    rocket.setDepth(10);

    function pauseSpineAnimation(gameObject, trackIndex = 0) {
      const currentTrack = gameObject.state.getCurrent(trackIndex);
      if (currentTrack) {
        // Save animation time
        currentTrackTime = currentTrack.trackTime;
        gameObject.state.setEmptyAnimation(trackIndex, 0); // Stop animation
        window.console.log('Animation paused:', currentTrackTime);
      } else {
        window.console.error('Animation track not found');
      }
    }

    function resumeSpineAnimation(gameObject, animationName, trackIndex = 0) {
      gameObject.setAnimation(trackIndex, animationName, false); // Start animation again
      const currentTrack = gameObject.state.getCurrent(trackIndex);
      if (currentTrack) {
        currentTrack.trackTime = currentTrackTime; // Reset track time
        window.console.log('Resume animation:', currentTrackTime);
      } else {
        window.console.error('Failed to resume animation.');
      }
    }

    function cleanUpGameOverlaysAnimation() {
      stopOverlayAnimations = true;
      delayedAnimations.forEach((obj) => {
        obj.remove();
      });
      delayedAnimations = [];
    }

    const showExplosion = () => {
      // eslint-disable-next-line
      let currentTrack = rocket.state.getCurrent(0);
      if (currentTrack) {
        currentTrackTime = currentTrack.trackTime;
        rocket.state.setAnimation(0, 'explosion', false);
        rocket.once('complete', () => {
          cleanUpGameOverlaysAnimation();
          emitGameEvent({ event: GameEvents.EXPLOSION_END });
        });
      }
      this.time.removeAllEvents();
    };

    rocket.stateData.setMix('takeoff', 'takeoff', 0.2);
    rocket.stateData.setMix('takeoff', 'all', 0.5);
    rocket.stateData.setMix('all', 'explosion', 0.2);
    rocket.stateData.setMix('explosion', 'all', 0.2);

    const takeoff = () => {
      this.time.addEvent({
        delay: 700,
        // repeatCount: 6,
        callback: () => {
          // Start from
          setAnimation(rocket, 'takeoff', true);
          setAnimation(background, 'takeoff', true);
        },
        loop: true,
      });
    };

    const playObjectFly = ({ spineObject, onComplete, maxDepth = 11 }) => {
      const startX = Phaser.Math.Between(50, this.cameras.main.width - 50);
      const startY = -100;
      const endY = this.cameras.main.height + 1000;
      spineObject.visible = true;
      spineObject.setPosition(startX, startY);
      const depth = Math.floor(Math.random() * maxDepth) + 1;
      spineObject.setScale(depth * 0.1);
      const duration = Phaser.Math.Between(4000, 10000);
      this.tweens.add({
        targets: spineObject,
        y: endY,
        duration,
        ease: 'Linear',
        onComplete: () => {
          spineObject.destroy();
          onComplete();
        },
      });

      this.tweens.add({
        targets: spineObject,
        x: {
          getStart: () => startX,
          getEnd: () => Phaser.Math.Between(50, this.cameras.main.width - 50),
        },
        duration: Phaser.Math.Between(1000, 2000),
        ease: 'Sine.easeInOut',
        yoyo: true,
        repeat: -1,
      });
    };
    const startRandomAnimationLoop = (spineName: FlyingObjectsNameT, minDelay: number, maxDelay: number, maxDepth = 11) => {
      const scheduleNextAnimation = () => {
        const spineObject = this.add.spine(
          this.game.scale.width / 2,
          this.game.scale.height,
          this.flyingObjectsData[spineName].name,
          this.flyingObjectsData[spineName].animations[0],
          true,
        );
        spineObject.visible = false;
        const delay = Phaser.Math.Between(minDelay, maxDelay);
        const delayed = spineObject.scene.time.delayedCall(delay, () => {
          if (stopOverlayAnimations) {
            return;
          }
          const onAnimationEnd = () => {
            if (stopOverlayAnimations) {
              return;
            }
            scheduleNextAnimation();
          };
          playObjectFly({ spineObject, onComplete: onAnimationEnd, maxDepth });
        });
        delayedAnimations.push(delayed);
      };
      scheduleNextAnimation();
    };

    // eslint-disable-next-line prefer-const
    sun = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.sunData.name,
      'sun_fly',
      false,
    );
    sun.visible = false;
    sun.setScale(0.4);

    // eslint-disable-next-line prefer-const
    coinsShower = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.coinsShowerData.name,
      null,
      false,
    );
    coinsShower.setScale(calculateScale(coinsShower, this.game.scale.width + 50, this.game.scale.height + 100));
    const playNextAnimationWithDelay = (spineObject: Phaser.GameObjects.Spine) => {
      const frameRate = this.game.loop.actualFps;
      const delay = (440 / frameRate) * 1000;
      const delayed = spineObject.scene.time.delayedCall(delay, () => {
        spineObject.setAnimation(0, 'sun_fly', false);
        sun.timeScale = 0.5;
        sun.visible = true;
      });
      delayedAnimations.push(delayed);
    };

    function startGame() {
      rocket.state.setAnimation(0, 'all', false).trackTime = 2;
      playNextAnimationWithDelay(sun);
      startRandomAnimationLoop('racoon', 1000, 2000);
      startRandomAnimationLoop('ufo', 2000, 20000);
      startRandomAnimationLoop('planet', 2000, 10000, 6);
      rocket.stateData.setMix('all', 'flying_with_blinking_hot', 0.2);
      rocket.state.addAnimation(0, 'flying_with_blinking_hot', true);
      background.state.setAnimation(0, 'takeoff', false).trackTime = 1;
      background.timeScale = 0.5;
    }

    const runCoinShower = () => {
      coinsShower.setDepth(20);
      coinsShower.state.setAnimation(0, 'win', false).trackTime = 1;
      coinsShower.once('complete', () => {
        emitGameEvent({ event: GameEvents.OVERLAY_COINS_RAIN_START });
      });
    };
    eventEmitter.on(GameEvents.SHOW_WIN_COINS_AVIATOR_LAYER, () => {
      runCoinShower();
    });
    eventEmitter.on(GameEvents.START_GAME, () => {
      this.time.removeAllEvents();
      startGame();
      this.isGameStarted = true;
    });
    eventEmitter.on(GameEvents.STOP_GAME, () => {
      this.isGameStarted = false;
      showExplosion();
      background.timeScale = 0;
    });
    eventEmitter.on(GameEvents.TAKEOFF, () => {
      takeoff();
    });
    eventEmitter.on(GameEvents.RESUME_GAME, () => {
      resumeSpineAnimation(rocket, 'all');
    });
    eventEmitter.on(GameEvents.PAUSE_GAME, () => {
      this.isGameStarted = false;
      pauseSpineAnimation(rocket, 0);
    });
    eventEmitter.on(GameEvents.SET_AVIATOR_MULTIPLIER, ({ value }) => {
      this.drawMultiplierScale(value);
    });

    eventEmitter.on(GameEvents.RESTART, () => {
      eventEmitter.off(GameEvents.START_GAME);
      eventEmitter.off(GameEvents.STOP_GAME);
      eventEmitter.off(GameEvents.TAKEOFF);
      eventEmitter.off(GameEvents.RESUME_GAME);
      eventEmitter.off(GameEvents.PAUSE_GAME);
      eventEmitter.off(GameEvents.RESTART);
      eventEmitter.off(GameEvents.WIN_GAME);
      eventEmitter.off(GameEvents.SET_AVIATOR_MULTIPLIER);
      eventEmitter.off(GameEvents.SHOW_WIN_COINS_AVIATOR_LAYER);
      this.scene.restart();
    });
    eventEmitter.on(GameEvents.WIN_GAME, () => {
      this.isGameStarted = false;
      // pauseSpineAnimation(rocket, 0);
      background.timeScale = 0;
      this.tweens.add({
        targets: rocket,
        y: -rocket.height,
        duration: 2000,
        ease: 'Power1',
        callbacks: () => {
          cleanUpGameOverlaysAnimation();
          runCoinShower();
        },
      });
    });
  }
}
