import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  RefObject, useEffect, useRef, useState,
} from 'react';
import Drawer from '../Drawer';
import CoinsSvg from './CoinsSvg';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import Button from '../Button';
import { numberWithCommas } from '../../helpers';

type AviatorWinModalProps = {
  multiplier: number | null
  coinsWon: number
  open: boolean
  rocketValueRef: RefObject<HTMLDivElement> | null
  onOkay: () => void
}
function AviatorWinModal({
  multiplier, coinsWon, onOkay, open, rocketValueRef,
}: AviatorWinModalProps) {
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [targetBoundings, setTargetBoundings] = useState<{x: number, y: number}>({ x: 0, y: 0 });
  const [startBoundings, setStartBoundings] = useState<{x: number, y: number}>({ x: 0, y: 0 });
  const targetText = useRef<HTMLDivElement>(null);
  const [counter, setCounter] = useState(1000);
  const [isPulsing, setIsPulsing] = useState(false);
  const [isBlinking, setIsBlinking] = useState(false);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    if (targetText.current) {
      const targetRect = targetText.current.getBoundingClientRect();
      const centerY = targetRect.top - targetRect.height + (targetRect.height / 2) + 20;
      setTargetBoundings({
        x: targetRect.left,
        y: centerY,
      });
    }
  }, [targetText.current]);
  useEffect(() => {
    const rocketValueTextDiv = rocketValueRef?.current;
    if (rocketValueTextDiv) {
      const targetRect = rocketValueTextDiv.getBoundingClientRect();
      setStartBoundings({
        x: targetRect.left,
        y: targetRect.top,
      });
      if (open) {
        setIsAnimating(true);
      }
    }
  }, [rocketValueRef?.current, open]);
  const animateOver = () => {
    setIsAnimating(false);
    setIsPulsing(true);
    setTimeout(() => {
      setIsCounting(true);
    }, 200);
  };
  useEffect(() => {
    if (!isCounting) return () => null;
    const targetValue = coinsWon;
    const duration = 2;
    let startTime: number;

    const updateCounter = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / 1000 / duration;

      if (progress < 1) {
        setCounter(Math.round(counter + (targetValue - counter) * progress));
        requestAnimationFrame(updateCounter);
      } else {
        setCounter(targetValue);
        setIsPulsing(false);
        setIsBlinking(true);
        setIsCounting(false);
      }
    };

    requestAnimationFrame(updateCounter);

    return () => cancelAnimationFrame(updateCounter as any);
  }, [isCounting, coinsWon]);
  return (
    <Drawer
      side="bottom"
      open={open}
      onClose={onOkay}
      closeStroke="gray-10"
      hideClose
      wrapperClassName="flex flex-col overflow-y-scroll h-80 shadow-xl rounded-t-[20px] bg-gradient-to-b from-[#B73B20] to-[#821220] z-[9] relative stroke-gray-10"
    >
      <div className="bg-aviatorBgStar bg-centerXPosition bg-no-repeat h-full stroke-[none]">
        <div className="flex justify-center mt-4">
          <CoinsSvg multiplier={multiplier} />
        </div>
        <div className="flex justify-center mt-4">
          <div className="text-[26px] flex justify-center items-center gap-2" ref={targetText}>
            <motion.div
              initial={{ scale: 1 }}
              animate={{
                scale: isPulsing ? [1, 1.2, 1] : 1,
                opacity: isBlinking ? [1, 0, 1] : 1,
              }}
              transition={{
                // eslint-disable-next-line no-nested-ternary
                duration: (isPulsing || isBlinking) ? 0.5 : 0,
                ease: 'easeInOut',
                // eslint-disable-next-line no-nested-ternary
                repeat: isPulsing ? Infinity : isBlinking ? 4 : 0,
                repeatType: 'loop',
                times: [0, 0.5, 1],
              }}
            >
              {numberWithCommas(counter)}
            </motion.div>
            <Coin width={22} height={22} />
          </div>
        </div>
        <div className="text-sm flex justify-center items-center gap-2 opacity-[80%]">
          Congratulations on your victory!
        </div>
        <Button className="w-[80%] mt-6 py-2" onClick={onOkay}>
          Okay
        </Button>
        <div
          className="fixed w-screen h-screen z-10 bottom-0 animation-container"
          style={{
            pointerEvents: 'none',
          }}
        >
          <AnimatePresence>
            {isAnimating && (
            <motion.div
              key="flying-text"
              initial={{ x: startBoundings.x, y: startBoundings.y }}
              animate={{
                x: [startBoundings.x, startBoundings.x, targetBoundings.x, targetBoundings.x],
                y: [startBoundings.y, (window.innerHeight * 0.3), targetBoundings.y, targetBoundings.y],
                scale: [1, 1.5, 0.5, 0],
              }}
              exit={{ opacity: 0 }}
              transition={{
                times: [0, 0.5, 0.9, 1],
                duration: 2.5,
                ease: 'easeInOut',
              }}
              onAnimationComplete={animateOver}
              className="text-5xl font-bold"
              style={{
                position: 'fixed',
              }}
            >
              X
              {multiplier}
            </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </Drawer>
  );
}

export default AviatorWinModal;
