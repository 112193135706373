import React, {
  useEffect, useRef, useState,
} from 'react';
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '../../store';
import { changeGame, getActiveSlotEffects, getSlotMachineSpinData } from '../../store/reducers/aviator/asyncActions';
import { SlotMachineSpinResponse } from '../../types';
import { updateProfileCoins, updateProfileEnergy, updateUserData } from '../../store/reducers/profile/asyncActions';
import Machine from './Machine/Machine';
import eventEmitter, { emitGameEvent, GameEvents } from '../../pages/Home/eventEmiter';
import MultiplierText from './MultiplierText';

function SlotMachineScreen() {
  const dispatch = useAppDispatch();
  const reduxStateRef = useRef<SlotMachineSpinResponse | null>(null);
  const { slotMachineSpin, earnedEffects } = useAppSelector(
    ({ aviator }) => aviator,
  );
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  reduxStateRef.current = slotMachineSpin;
  const [isSpinning, setIsSpinning] = useState<boolean>(false);

  let endGameTimeout: ReturnType<typeof setTimeout> | null = null;
  const spinTimeout: ReturnType<typeof setTimeout> | null = null;
  useEffect(() => () => {
    if (endGameTimeout) {
      clearTimeout(endGameTimeout);
    }
    if (spinTimeout) {
      clearTimeout(spinTimeout);
    }
  }, []);
  const endGame = (spinData: SlotMachineSpinResponse) => {
    const spinEffects = spinData?.effects;
    if (spinEffects?.length) {
      const spinEffect = spinEffects[0];
      switch (spinEffect.name) {
        case 'regenerate_energy':
          eventEmitter.emit(GameEvents.OVERLAY_SHOW_ENERGY_ANIMATION);
          eventEmitter.emit(GameEvents.SLOT_HIDE_MIDDLE);
          dispatch(updateProfileEnergy({ energy: spinEffect.amount, replace: true }));
          break;
        case 'reward_multiplier':
          eventEmitter.emit(GameEvents.OVERLAY_SHOW_MULTIPLIER_ANIMATION, spinEffect.amount);
          eventEmitter.emit(GameEvents.SLOT_HIDE_MIDDLE);
          break;
        case 'bonus':
          dispatch(updateProfileCoins({ coins: spinEffect.amount, replace: false }));
          break;
        case 'aviator':
          eventEmitter.emit(GameEvents.OVERLAY_GALAXY_TRANSITION);
          endGameTimeout = setTimeout(() => {
            dispatch(changeGame('aviator'));
          }, 1000 * 2.4);
          break;
        default:
          break;
      }
    }
    if (spinEffects?.[0]?.name !== 'aviator') {
      setIsSpinning(false);
    }
    dispatch(getActiveSlotEffects());
  };
  const spinReels = async () => {
    if (userData && userData?.energy >= 10) {
      setIsSpinning(true);
      const resp = await dispatch(getSlotMachineSpinData());
      if (resp?.payload) {
        dispatch(updateUserData((resp.payload as SlotMachineSpinResponse).user));
        emitGameEvent({ event: GameEvents.SLOT_START_SPIN, targetSlots: (resp.payload as SlotMachineSpinResponse).slots.map((slot) => slot.type) });
      }
    }
  };

  useEffect(() => {
    dispatch(getActiveSlotEffects());
    const handleStopEvent = () => {
      if (reduxStateRef.current) {
        endGame(reduxStateRef.current);
      }
    };
    eventEmitter.on(GameEvents.SLOT_STOP_SPIN, handleStopEvent);
    return () => {
      eventEmitter.off(GameEvents.SLOT_STOP_SPIN, handleStopEvent);
    };
  }, []);
  useEffect(() => {
    const startAnimation = () => {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 50);
    };
    eventEmitter.on(GameEvents.ENERGY_HIT_SCREEN, startAnimation);
    return () => {
      eventEmitter.off(GameEvents.ENERGY_HIT_SCREEN, startAnimation);
    };
  }, []);

  const rewardMultipler = earnedEffects?.find((el) => el.effect.name === 'reward_multiplier');
  const [isAnimating, setIsAnimating] = useState(false);

  return (
    <div className="bg-slotBg bg-cover absolute top-0 w-screen h-screen flex flex-col items-center justify-end">
      <div className="w-full h-fit fixed bottom-5">
        <MultiplierText rewardMultipler={rewardMultipler} />
        <motion.div
          className="box"
          animate={{ y: isAnimating ? 2 : 0 }}
          transition={{
            type: 'spring',
            stiffness: 1000,
            damping: 20,
          }}
        >
          <Machine onSpin={spinReels} isSpinning={isSpinning} />
        </motion.div>
      </div>
    </div>
  );
}

export default SlotMachineScreen;
