// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react';
import * as PIXI from 'pixi.js';
import { Spine, TextureAtlas } from 'pixi-spine';
import Phaser from 'phaser';
import { SlotTypes } from '../../../types/constants';
import { SlotMachineSpinResponse } from '../../../types';
import eventEmitter, { emitGameEvent, GameEvents } from '../../../pages/Home/eventEmiter';
import { slotMachinePixiApp } from '../../../Layouts/AppWrapper';

const fileListEdit = [
  {
    name: SlotTypes.energy_refill, jsonPath: 'energy/energy_small.json', atlasPath: 'energy/energy_small.atlas', animations: ['animation'], scale: 1.8, customOffset: { x: 0, y: 0 },
  },
  {
    name: SlotTypes.coin_bag, jsonPath: 'coin_bag/coin_bag.json', atlasPath: 'coin_bag/coin_bag.atlas', animations: ['animation'], scale: 0.8, customOffset: { x: 0, y: 0 },
  },
  {
    name: SlotTypes.treasure, jsonPath: 'treasure/treasure.json', atlasPath: 'treasure/treasure.atlas', animations: ['animation'], scale: 0.9, customOffset: { x: 0, y: 0 },
  },
  {
    name: SlotTypes.multiplier_x2, jsonPath: 'multiplier/multiplier.json', atlasPath: 'multiplier/multiplier.atlas', animations: ['multiplier_x2'], scale: 1.15, customOffset: { x: -25, y: 115 },
  },
  {
    name: SlotTypes.multiplier_x5, jsonPath: 'multiplier/multiplier.json', atlasPath: 'multiplier/multiplier.atlas', animations: ['multiplier_x5'], scale: 1.15, customOffset: { x: -25, y: 115 },
  },
  {
    name: SlotTypes.multiplier_x3, jsonPath: 'multiplier/multiplier.json', atlasPath: 'multiplier/multiplier.atlas', animations: ['multiplier_x3'], scale: 1.15, customOffset: { x: -25, y: 115 },
  },
  // {
  //   name: SlotTypes.revive, jsonPath: 'second_chance/second_chance.json', atlasPath: 'second_chance/second_chance.atlas', animations: ['animation'],
  // },
  // {
  //   name: 'coin_booster', jsonPath: 'coin_booster/coins_booster.json', atlasPath: 'coin_booster/coins_booster.atlas', animations: ['animation'],
  // },
  // {
  //   name: SlotTypes.aviator, jsonPath: 'reduce_crash_pr_booster/reduce_crash_pr_booster.json', atlasPath: 'reduce_crash_pr_booster/reduce_crash_pr_booster.atlas', animations: ['animation'], scale: 1.1, customOffset: { x: 0, y: 0 },
  // },
  {
    name: SlotTypes.aviator, jsonPath: 'galaxy/galaxy.json', atlasPath: 'galaxy/galaxy.atlas', animations: ['animation'], scale: 1.6, customOffset: { x: 0, y: 0 },
  },
];
const app = slotMachinePixiApp;
const columns = [[], [], []];
const folderPath = '../spine/slot-machine/';
const numberOfDisplayedRows = 4;
async function loadSpineAtlas(path: string) {
  const pathArray = path.split('/');
  const folder = pathArray.slice(0, pathArray.length - 1).join('/');
  const name = path.split('/').pop()?.replace('.json', '');
  const atlasPath = `${folder}/${name}.atlas`;
  const rawAtlas = await fetch(atlasPath).then((res) => res.text());
  return new TextureAtlas(rawAtlas, ((line, callback) => {
    callback(PIXI.BaseTexture.from(`${folder}/${line}`));
  }));
}

const addAnimation = (file, colIndex, rowIndex) => {
  const animation = new Spine(file.spineData);
  // add the animation to the scene and render...
  app.stage.addChild(animation);

  // add the animation to the scene and render...
  app.stage.addChild(animation);
  if (animation.state.hasAnimation(file.animations[0])) {
    animation.state.setAnimation(0, file.animations[0], true);
    animation.scale.set(calculateScale(animation, 75, 64) * file.scale);
    if (typeof colIndex === 'number' && typeof rowIndex === 'number') {
      const position = calculateAnimationPosition(colIndex, rowIndex);
      animation.x = position.x + file.customOffset.x;
      animation.y = position.y + file.customOffset.y;
    }
    animation.state.timeScale = 0;
    animation.autoUpdate = true;
    animation.name = file.name;
    animation.animations = file.animations;
    animation.customOffset = file.customOffset;
    animation.fileData = file;
  }
  return animation;
};
const intialRenderAnimations = (fileList) => {
  for (let colIndex = 0; colIndex < 3; colIndex++) {
    // eslint-disable-next-line no-plusplus
    for (let rowIndex = 0; rowIndex < fileListEdit.length; rowIndex++) {
      const file = fileList[Phaser.Math.Between(0, fileListEdit.length - 1)];
      const animation = addAnimation(file, colIndex, rowIndex);
      animation.visible = (rowIndex < numberOfDisplayedRows && rowIndex !== 0);
      columns[colIndex].push(animation);
    }
  }
};
async function loadAllSpineData() {
  const spineDataArr = [];
  await Promise.all(
    fileListEdit.map(async (animationFile) => {
      const tempFile = { ...animationFile };
      const path = folderPath + animationFile.jsonPath;

      try {
        const spineAtlas = await loadSpineAtlas(path);

        PIXI.Assets.add({
          src: path,
          alias: path,
          data: {
            spineAtlas,
          },
        });

        const resource = await PIXI.Assets.load(path);
        const { spineData } = resource;

        Object.assign(animationFile, { spineData });
        tempFile.spineData = spineData;

        spineDataArr.push({
          ...tempFile,
          spineData,
        });
      } catch (error) {
        window.console.error(`Preload error ${path}:`, error);
      }
    }),
  );

  return spineDataArr;
}
loadAllSpineData().then((dataArr) => {
  intialRenderAnimations(dataArr);
});
const calculateScale = (animation: any, targetWidth: number, targetHeight: number) => {
  const originalWidth = animation.width;
  const originalHeight = animation.height;
  const scaleX = targetWidth / originalWidth;
  const scaleY = targetHeight / originalHeight;
  return Math.min(scaleX, scaleY);
};
const calculateAnimationPosition = (colIndex: number, rowIndex: number): { x: number; y: number } => {
  // const sceneWidth = app.renderer.width;
  // const sceneHeight = app.renderer.height;
  const sceneWidth = 335;
  const sceneHeight = 258;
  const baseXOffset = sceneWidth / 6; // 60
  const baseYOffset = sceneHeight / 7;
  const baseXSpacing = sceneWidth / 3.2;
  const baseYSpacing = sceneHeight / 3.2;
  const xPosition = (baseXSpacing * colIndex) + (baseXOffset);
  const yPosition = (baseYSpacing * (rowIndex - 1)) + (baseYOffset);
  return { x: xPosition, y: yPosition };
};

const increment = 2;
const initialSpeed = 30;
const maxSpeed = 100;
const reverseSpeed = 250;
const reverseOffset = 10;
export const getMiddleElementPosition = () => {
  const sceneWidth = 335;
  const sceneHeight = 258;
  const baseYOffset = sceneHeight / 7;
  const baseXOffset = sceneWidth / 6;
  const calculatedPosition = calculateAnimationPosition(1, 2);
  const getGlobalPosition = (animation) => ({
    elementWidth: animation.width,
    elementHeight: animation.height,
    elementX: animation.x,
    elementY: animation.y,
    elementOffset: animation.customOffset,
    baseYOffset,
    baseXOffset,
    calculatedPosition,
    elementScale: columns[1][2].scale,
  });
  return getGlobalPosition(columns[1][2]);
};
export const getSlotCanvasRect = () => app.view.getBoundingClientRect() as DOMRect;
function SlotMachinePixi() {
  let animationFrameIds = [];
  const updateDisplay = (columnIndex: number): void => { // TODO: add blur
    const column = columns[columnIndex];
    // eslint-disable-next-line no-plusplus
    for (let rowIndex = 0; rowIndex < column.length; rowIndex++) {
      const spineObject = column[rowIndex];
      spineObject.visible = (rowIndex < numberOfDisplayedRows && rowIndex !== 0); // Display top 4
      const position = calculateAnimationPosition(columnIndex, rowIndex);
      spineObject.x = position.x + spineObject.customOffset.x;
      spineObject.y = position.y + spineObject.customOffset.y;
    }
  };

  const animateReverse = ({
    spineObject, currentTime, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, columnIndex, targetValues,
  }) => {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / reverseSpeed, 1);

    try {
      spineObject.y = startYPosition + (endYPosition - startYPosition) * progress;
    } catch (e) {
      window.console.error(e);
    }

    if (progress < 1) {
      animationFrameIds.push(requestAnimationFrame((t) => animateReverse({
        currentTime: t, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, columnIndex, targetValues, spineObject,
      })));
    } else {
      animationsCompleted[rowIndex] = true;

      if (animationsCompleted.every((done) => done)) {
        if (columns.length - 1 === columnIndex) {
          if (shouldAnimateMiddleRow(targetValues)) {
            columns.forEach((col) => {
              col[2].state.timeScale = 1;
              col[2].state.setAnimation(0, col[2].spineData.animations[0].name, false);
            });
            setTimeout(() => {
              emitGameEvent({ event: GameEvents.SLOT_COINS_EXPLOSION });
            }, 1000);
          }
          setTimeout(() => {
            emitGameEvent({ event: GameEvents.SLOT_STOP_SPIN });
          }, 1000);
        }
      }
    }
  };

  const startSpinAnimation = (columnIndex: number, cycles: number, targetValues: string[]): void => {
    const column = columns[columnIndex];
    let completedCycles = 0;
    let currentIndex = 0;
    let currentDuration = initialSpeed;
    const reverseAnimation = (reverseColumnIndex: number): void => {
      const reverseColumn = columns[reverseColumnIndex];
      const animationsCompleted = new Array(reverseColumn.length).fill(false);

      for (let rowIndex = 0; rowIndex < reverseColumn.length; rowIndex++) {
        const spineObject = reverseColumn[rowIndex];
        spineObject.visible = true;

        const startYPosition = spineObject.y + reverseOffset;
        const endYPosition = spineObject.y;
        const startTime = performance.now();

        animationFrameIds.push(requestAnimationFrame((currentTime) => animateReverse({
          currentTime, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, columnIndex, targetValues, spineObject,
        })));
      }
    };
    const animate = ({
      spineObject, currentTime, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, targetValuesAnimate,
    }) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / currentDuration, 1);

      try {
        spineObject.y = startYPosition + (endYPosition - startYPosition) * progress;
      } catch (e) {
        window.console.log(e);
      }
      if (progress < 1) {
        animationFrameIds.push(requestAnimationFrame((t) => animate({
          currentTime: t, spineObject, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, targetValuesAnimate: targetValues,
        })));
      } else {
        animationsCompleted[rowIndex] = true;
        if (rowIndex === 0 && completedCycles <= cycles) {
          const targetSymbols = Phaser.Utils.Array.Shuffle([...fileListEdit]);
          const file = targetSymbols[currentIndex % targetSymbols.length];
          const newSpineObject = addAnimation(file, columnIndex, 0);
          column.unshift(newSpineObject);
          column.pop();
        }

        if (animationsCompleted.every((done) => done)) {
          updateDisplay(columnIndex);
          if (completedCycles < cycles) {
            completedCycles++;
            currentIndex++;
            currentDuration = Math.min(currentDuration + (increment * columnIndex), maxSpeed);
            animateColumn(); // Restart animation for next cycle
          } else { // all cycles completed
            const targetValue = targetValuesAnimate[columnIndex];
            if ((column[2] && column[2].name !== targetValue)
            ) {
              currentIndex++;
              currentDuration = Math.min(currentDuration + increment * columnIndex, maxSpeed);
              animateColumn(); // loop while not reached targetValue
            } else {
              reverseAnimation(columnIndex);
            }
          }
        }
      }
    };

    const animateColumn = () => {
      const animationsCompleted = new Array(column.length).fill(false);
      for (let rowIndex = 0; rowIndex < column.length; rowIndex++) {
        const spineObject = column[rowIndex];
        spineObject.visible = true;

        const startYPosition = calculateAnimationPosition(columnIndex, rowIndex).y + spineObject.customOffset.y;
        const endYPosition = calculateAnimationPosition(columnIndex, rowIndex + 1).y + spineObject.customOffset.y + reverseOffset;

        const startTime = performance.now();
        animationFrameIds.push(requestAnimationFrame((t) => animate({
          currentTime: t, spineObject, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, targetValuesAnimate: targetValues,
        })));
      }
    };

    animateColumn();
  };
  const shouldAnimateMiddleRow = (slotsArr): boolean => {
    const slotTypesToAnimate = [SlotTypes.coin_bag, SlotTypes.treasure];
    if (slotTypesToAnimate.includes(slotsArr[0])) {
      const allEqual = (arr: string[]) => arr.every((v) => v === arr[0]);
      return allEqual(slotsArr);
    }
    return false;
  };

  const clearAllAnimationsFrames = () => { // clear requestAnimationFrame timers
    animationFrameIds.forEach((id) => cancelAnimationFrame(id));
    animationFrameIds = [];
  };
  useEffect(() => () => clearAllAnimationsFrames(), []);
  const startSpin = (targetSlots: SlotMachineSpinResponse['slots'][number]['type']) => {
    clearAllAnimationsFrames();
    columns.forEach((column, colIndex) => {
      startSpinAnimation(colIndex, 8 + (colIndex * 2), targetSlots);
    });
  };
  useEffect(() => {
    const slot = document.getElementById('slot-test'); // TODO: add loader event
    slot.appendChild(app.view);
  }, []);
  useEffect(() => {
    const handler = (data) => {
      if (data?.targetSlots) {
        startSpin(data.targetSlots);
      }
    };
    const hideMiddle = () => {
      if (columns[1][2]) {
        columns[1][2].visible = false;
      }
    };
    const showMiddle = () => {
      if (columns[1][2]) {
        columns[1][2].visible = true;
      }
    };
    eventEmitter.on(GameEvents.SLOT_START_SPIN, handler);
    eventEmitter.on(GameEvents.SLOT_HIDE_MIDDLE, hideMiddle);
    eventEmitter.on(GameEvents.SLOT_SHOW_MIDDLE, showMiddle);

    return () => {
      eventEmitter.off(GameEvents.SLOT_START_SPIN, handler);
      eventEmitter.off(GameEvents.SLOT_HIDE_MIDDLE, hideMiddle);
      eventEmitter.off(GameEvents.SLOT_SHOW_MIDDLE, showMiddle);
    };
  }, []);

  return (
    <div id="slot-test" />
  );
}

export default SlotMachinePixi;
