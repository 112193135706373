import { useAppSelector } from '../../../store';

function BottomScreen() {
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const maxEnergy = userSettings?.max_energy || 100;
  const userEnergy = Math.floor(userData?.energy || 0);
  const currentPercentage = (userEnergy / maxEnergy) * 100;
  return (
    <div className="h-full w-full mx-auto">
      <svg
        height="100%"
        viewBox="0 0 551 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0" x="0" y="0" width="551" height="95">
          <rect x="0" y="0" width={`${currentPercentage}%`} height="95" fill="white" />
          {/* <rect x="531" y="0" width="20" height="95" fill="black" /> */}
        </mask>
        <g clipPath="url(#clip0_296_3445)" mask="url(#mask0)">
          <path d="M4.55049 85H526.45C529.863 85 531.91 81.554 530.545 78.3378C530.545 78.3378 510.752 36.9865 499.605 14.0135C495.282 5.28378 486.637 0 477.081 0L54.8294 0C45.2741 0 36.6289 5.28378 32.5338 14.0135C21.386 36.7568 0.682885 78.3378 0.682885 78.3378C-1.13717 81.3243 1.1379 85 4.55049 85Z" fill="url(#paint0_linear_296_3445)" />
          <path opacity="0.8" d="M514.619 55.365C511.889 49.6217 500.514 26.1893 498.466 22.5136C492.324 10.5677 487.319 4.59471 472.758 4.13525H59.6066C53.4639 4.13525 50.9613 4.13525 46.8662 6.20282C41.8611 8.50012 38.9035 13.7839 35.7184 18.3785C32.5333 22.9731 16.6078 57.6623 16.1528 58.5812L514.619 55.365Z" fill="url(#paint1_linear_296_3445)" />
          <path opacity="0.6" d="M33.2159 25.5001C36.4009 20.9055 38.676 16.5407 43.4536 14.0136C47.0937 12.1758 51.1889 11.2569 55.5115 11.2569H475.716C487.319 11.2569 496.874 20.4461 502.107 29.865C503.244 31.9325 504.609 34.2298 505.974 36.9866C503.244 31.2434 500.514 26.1893 498.466 22.2839C492.324 10.338 487.319 4.36498 472.531 3.90552H58.9241C52.7814 3.90552 50.9614 4.13525 46.8662 5.97308C41.8611 8.27038 38.676 13.7839 35.7184 18.3785C32.5333 23.4325 27.0732 35.3785 26.6182 36.2974C28.6657 33.3109 30.9408 28.7163 33.2159 25.5001Z" fill="url(#paint2_linear_296_3445)" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_296_3445" x1="265.519" y1="96.0677" x2="265.519" y2="-4.13261" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0099FF" />
            <stop offset="0.0350828" stopColor="#26AEFE" />
            <stop offset="0.0963897" stopColor="#63D1FC" />
            <stop offset="0.1522" stopColor="#8FEAFA" />
            <stop offset="0.1939" stopColor="#B5FFF9" />
            <stop offset="0.24" stopColor="#ABFDF7" />
            <stop offset="0.3381" stopColor="#67EBEC" />
            <stop offset="0.4323" stopColor="#00D1DA" />
            <stop offset="0.6037" stopColor="#009BC8" />
            <stop offset="0.6364" stopColor="#008FC4" />
            <stop offset="0.7572" stopColor="#0066B8" />
            <stop offset="0.8527" stopColor="#004DB0" />
            <stop offset="0.9106" stopColor="#0044AD" />
          </linearGradient>
          <linearGradient id="paint1_linear_296_3445" x1="265.483" y1="38.7636" x2="265.483" y2="3.57402" gradientUnits="userSpaceOnUse">
            <stop offset="0.00372404" stopColor="white" stopOpacity="0" />
            <stop offset="0.9963" stopColor="white" stopOpacity="0.6" />
          </linearGradient>
          <linearGradient id="paint2_linear_296_3445" x1="266.358" y1="24.952" x2="266.358" y2="3.74953" gradientUnits="userSpaceOnUse">
            <stop offset="0.00372428" stopColor="white" stopOpacity="0" />
            <stop offset="0.9963" stopColor="white" stopOpacity="0.8" />
          </linearGradient>
          <clipPath id="clip0_296_3445">
            <rect width="531" height="85" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div className="font-Russo fixed inset-0 flex items-center justify-center font-bold text-sm text-[#071573]">
        {`${userEnergy} / ${maxEnergy}`}
      </div>
    </div>
  );
}

export default BottomScreen;
