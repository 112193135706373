import { useEnergyRecoveryTimer } from '../../../hooks/useEnergyRecoveryTimer';

function MiddleScreen() {
  const { countdown } = useEnergyRecoveryTimer();
  if (!countdown.seconds && !countdown.minutes) {
    return null;
  }
  return (
    <div className="font-Russo text-[9px]">
      1
      {' '}
      <span className="text-[#83F3F1]">
        Spin in
      </span>
      {' '}
      <span>
        {countdown.formatedMinutes}
        :
        {countdown.formatedSecond}
      </span>
    </div>
  );
}
export default MiddleScreen;
