import { useEffect } from 'react';
import { ReactComponent as SpinSvg } from './Spin_button.svg';

interface SpinButtonProps {
  onClick: () => void
  isSpinning: boolean
}
function SpinButton({ onClick, isSpinning }: SpinButtonProps) {
  const handleClick = () => {
    const animatedPath = document.getElementById('animate');
    if (animatedPath) {
      animatedPath.id = 'eMuaYO0y5tU15_to';
    }
    if (!isSpinning) {
      onClick();
    }
  };
  useEffect(() => {
    if (!isSpinning) {
      const animatedPath = document.getElementById('eMuaYO0y5tU15_to');
      if (animatedPath) {
        animatedPath.id = 'animate';
      }
    }
  }, [isSpinning]);
  return (
    <div className="fixed mt-[-10px]" onClick={handleClick}>
      <SpinSvg className="w-full h-full" />
    </div>
  );
}

export default SpinButton;
