import React, { useEffect, useRef, useState } from 'react';
// import AvatarPlaceholder from '../../assets/images/avatar-placeholder-v2.svg';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import { TopPlayerT } from '../../types';
import { numberWithCommas } from '../../helpers';

type LeaderboardUserCardProps = Pick<TopPlayerT, 'username' | 'league'| 'total_earned_coins'> &{
    isUser?: boolean
    first_name?: string
    last_name?: string
    currentUserId: number
    telegram_id?: number
    onVisible?: () => void
    onHidden?: () => void
}
function LeaderboardUserCard({
  username, first_name: firstName, last_name: lastName, league, total_earned_coins: totalCoins, isUser, currentUserId, telegram_id: telegramID, onVisible, onHidden,
}: LeaderboardUserCardProps) {
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // viewport
        rootMargin: '0px', // no margin
        threshold: 0.5, // 50% of target visible
      },
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Clean up the observer
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);
  useEffect(() => {
    if ((currentUserId === telegramID) && isVisible) { // to show/hide user card from list
      onVisible?.();
    } else if ((currentUserId === telegramID) && !isVisible) {
      onHidden?.();
    }
  }, [isVisible, currentUserId, telegramID]);
  return (
    <div className={`${isUser ? 'bg-black-2' : 'bg-black-light-bg'} p-2 rounded-10 flex justify-between`} ref={targetRef}>
      <div className="flex pl-[.125rem]">
        {/* <img src={AvatarPlaceholder} alt="avatar" className="rounded max-w-[50px] max-h-[50px] h-full w-fit" /> */}
        <div className="flex flex-col items-start">
          <div className="text-12 font-bold">{username || (`${firstName} ${lastName}`)}</div>
          <div className="flex items-center gap-2 text-14 font-bold pt-1">
            {numberWithCommas(totalCoins)}
            <Coin width="14px" height="14px" />
          </div>
        </div>
      </div>
      <div className={`text-22 my-auto mx-0 font-bold pr-1
      ${league.rank === 1 && 'text-[#FFBB66]'}
      ${league.rank === 2 && 'text-[#D2D0CC]'}
      ${league.rank === 3 && 'text-[#F27C57]'}
      ${isUser && 'text-gray-300'}
      `}
      >
        #
        {numberWithCommas(league.rank)}
      </div>
    </div>
  );
}

export default LeaderboardUserCard;
